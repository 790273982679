//siro
//import React from 'react';
/*
import React, { useRef } from 'react'

const x = "111";
const y = "222";
const z = "333";
//const secret = "chocoholic";
//const status = "Finalized";
//const memo = "thaifood123";

const url = 'https://www.skyviq.com/ipnpos1.php';

export default function TestComp() {
//  const butRef = React.useRef(null);
    const butRef = React.useRef<HTMLButtonElement>(null);
//  const redirectBtn = useRef<HTMLButtonElement>(null);

const a = 1;
const b = 2;
const c = 2;

window.addEventListener('load', function () {
        if(a * b === c) {
            triggerPost();
        }
      })

  // if you want to submit the form, you need call the function
  const triggerPost = () => {
    butRef.current?.click()
  }

  return (
    <form action={url} method="post">
      <div className='inputForm'>
      <input type="hidden" name="x" value={x} />
      <input type="hidden" name="y" value={y} />
      <input type="hidden" name="z" value={z} />
//      <input type="hidden" name="secret" value={secret} />
//      <input type="hidden" name="status" value={status} />
//      <input type="hidden" name="memo" value={memo} />
      <button type="submit" ref={butRef} hidden>submit</button>
      </div>
    </form>
  )
} 

  const triggerPost = () => {
    butRef.current?.click()
  }
*/


//sergey
/*
import React from 'react';
import ReactDOM from 'react-dom';
import '../../index.css';
import App from '../../App';
import RedirectFunc from '../../view/redirect';
mport reportWebVitals from '../../reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
 </React.StrictMode>,
 document.getElementById('root')
);


import React, { useRef } from 'react'

const url = 'https://www.google.com';        
declare global {
    interface Window {
        myCounter: number;
    }
}
const RedirectFunc = () => {
    const redirectBtn = useRef<HTMLButtonElement>(null);    
    const x = 3;
    const y = 5;
    const z = 15;

    window.addEventListener('load', function () {
        if(x * y === z) {
            triggerRedirectBtn();
        }
      })   

    const triggerRedirectBtn = () => {        
        redirectBtn.current?.click()        
    }

    return (
        <div className="redirectView">
            <div className='title'>
                <h2>This is simple react+php project</h2>
            </div>
            <div className='content'>            
                <h3>
                &nbsp;   x = {x},
                  y = {y},
                   z = {z}
                </h3>    
                <h3>
                &nbsp;  x * y = z ?                    
                </h3>
                <h4>
                ? If this is true page will redirect result.php.
                </h4>                
            </div>   
            <div className='redirectForm'>
                <form action={url} method="POST">                    
                    <div className='inputForm'>
                        <input name="x" type="text" value={x} required />
                        <input name="y" type="text" value={y} required />
                        <input name="z" type="text" value={z} required />
                    </div>                    
                    <button className='redirectButton' ref={redirectBtn}  type="submit">Redirect</button>
                </form>
            </div>         
        </div>
    )
}

export default RedirectFunc ;



//import React, { useRef } from 'react';


/*
const Example = () => {
  const inputRef = React.useRef(null)
        
  return (
    <div ref={inputRef} onClick={()=> console.log('clicked')}>
      hello
    </div>
  )
}
    

inputRef.current.handleClick()
*/






//import request from "../../../../node_modules/superagent";

/*
request
.post('https://www.skyviq.com/ipnpos1.php')
.set('Content-Type', 'application/x-www-form-urlencoded')
.send({ secret: "gumbo", status: "excellent" })
.end(function(err, res){
console.log(res.text);
});  
*/

//import { Button, View } from 'react-native';

/*
const App = () => {
  const btnRef = useRef();

  return (
    <View>
      <Button
        title="Ref"
        onPress={() => {
          console.info('Ref props', btnRef.current.props);
          btnRef.current.props.onPress();
          btnRef.current.props.onHandlePress();
        }}
      />
      <Button
        title="Click Me"
        ref={btnRef}
        onPress={() => console.log('swedishonPress props called')}
        onHandlePress={() => console.log('swedishCustom onHandlePress called')}
      />
    </View>
  );
};

export default App;
*/


//gsl beg
//https://www.pluralsight.com/guides/how-to-handle-ajax-with-react

import React, { Component } from "react";
import axios from "axios";
//import React, { useState, useEffect } from 'react';
import React, { useEffect } from 'react';
//gsl end

const secret = "chocoholic";
const mid = "3369";
const ttid = "139";
const amount = "1";
const signature = "";
const memo = "xid6789";
const status = "New";

//https://www.pluralsight.com/guides/how-to-send-data-via-ajax-in-react
//url: 'https://webhook.site/724d224b-9fb6-4300-b74c-3fabe80bab86?secretnew=' + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&memo=' + memo + '&status=' + status + '&signature=' + signature,
//url: 'https://www.skyviq.com/ipnpos.php?secretnew=' + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&memo=' + memo + '&status=' + status + '&signature=' + signature,

//            url: 'https://www.skyviq.com/ipnpos.php?secretnew=' + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&memo=' + memo + '&status=' + status + '&signature=' + signature,
//            data: {
//                type: "hot",
//                limit: 10
//            }

function componentDidMount() {
      axios({
            method: 'post',
            url: 'https://www.skyviq.com/ipnpos.php?secret=' + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&memo=' + memo + '&status=' + status + '&signature=' + signature,
//            url: 'https://www.skyviq.com/ipnpos.php?secret=' + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&status=' + status + '&signature=' + signature + '&label=' + label + '&memo=' + memo + '&recipient=' + recipient + '&recipient1=' + recipient1 + '&percent=' + percent + '&percent1=' + percent1 + '&message=' + message + '&reference=' + reference + '&referenceNew=' + referenceNew + '&spltokenNew=' + spltokenNew,
           data: {
                type: "hot",
                limit: 10
            }
        })
        .then(res => this.setState({ recipes: res.data }));
}


/*
componentDidMount() {
    const recipeUrl = 'https://www.skyviq.com/ipnpos1.php';
    const postBody = {
        type: "hot",
        limit: 10,
	"secret": "chocoholic",
	amount: "0.33",
	status: "Finalized",
	signature: signature
    };

    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody)
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(recipes => {
            this.setState({ recipes });
        });
}
*/

//https://stackoverflow.com/questions/66023819/axios-post-request-to-send-form-data-in-reactjs
/*
const onSubmit = async () => {
   var bodyFormData = new FormData();
   bodyFormData.append("secret", "chocoholic");
   bodyFormData.append("status", "Finalized");
   bodyFormData.append("amount", amount);
      
     await axios({
        method: "post",
        url: "https://www.skyviq.com/ipnpos1.php",
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
*/


//https://stackoverflow.com/questions/37002377/how-to-call-javascript-function-in-html-without-clicking-any-button


//siro orig
/*
export default function TestComp() {
  return (
    <form action="https://www.skyviq.com/ipnpos1.php" method="post">
      <input type="hidden" name="secret" value={secret} />
      <input type="hidden" name="status" value={status} />
      <input type="hidden" name="memo" value={memo} />
      <input id="MyButton" type="submit" value="submit" />
    </form>
  )
}

this.myButton.click()

window.onload = function() {
  TestComp();
}
*/

//siro new
/*
import React from 'react'

const secret = "chocoholic";
const status = "Finalized";
const memo = "siro333";

export default function TestComp() {
  const butRef = React.useRef(null);

  // if you want to submit the form, you need call the function
//  const _someTimeRunning = () => {
//    butRef.current.click()
//  }

  return (
    <form action="https://www.skyviq.com/ipnpos1.php" method="post">
      <input type="hidden" name="secret" value={secret} />
      <input type="hidden" name="status" value={status} />
      <input type="hidden" name="memo" value={memo} />
      <button type="submit" ref={butRef} hidden>submit</button>
    </form>
  )
} 

  // if you want to submit the form, you need call the function
  const someTimeRunning = () => {
    butRef.current?.click();
  }





class MyComponent extends React.Component {
  render() {
    return (
      <div onClick={this.handleClick}>
    <form action="https://www.skyviq.com/ipnpos1.php" method="post">
      <input type="hidden" name="secret" value={secret} />
      <input type="hidden" name="status" value={status} />
      <input type="hidden" name="memo" value={memo} />
        <input type="submit" ref={input => this.inputElement = input} />
    </form>
console.log('goldfinger')
      </div>
    );
  }

  handleClick = (e) => {
    this.inputElement.click();
  }
}
*/


window.onload = function() {
  componentDidMount();
}

componentDidMount();

/*
async function simulateMouseClick(el) {
  let opts = {view: window, bubbles: true, cancelable: true, buttons: 1};
  el.dispatchEvent(new MouseEvent("mousedown", opts));
//  await new Promise(r => setTimeout(r, 50));
  el.dispatchEvent(new MouseEvent("mouseup", opts));
  el.dispatchEvent(new MouseEvent("click", opts));
}

let btn = document.querySelector("div[aria-label=start]");
simulateMouseClick(btn);
console.log("The button has been clicked.");
*/


//useEffect(() => {
//    componentDidMount();
//  }, [])

/*
useEffect(() => {
  const fetchData = async () => {
    await sleep(1000);
    // this will log 'Hello Word' to the console
    console.log('Hello World');
    componentDidMount();
  };

  fetchData()
    // make sure to catch any error
    .catch(console.error);;
}, [])
*/

//https://stackoverflow.com/questions/40091000/simulate-click-event-on-react-element/54316368#54316368
/*
export default const () => {

    // Defining the ref constant variable
    const inputRef = React.useRef(null);

    // example use
    const keyboardEvent = () => {
      inputRef.current.handleClick(); //Trigger click
    }

    // registering the ref
    return (
       <div ref={inputRef} onClick={()=> console.log('clicked')}>
          hello
       </div>
    )
}
*/

//let elem = document.querySelector('YOUR SELECTOR');
//Grab mouseEvent by firing "click" which wouldn't work, but will give the event
/*
let event;
likeBtn.onclick = e => {
  event = Object.assign({}, e);
  event.isTrusted = true; //This is key - React will terminate the event if !isTrusted
};
elem.click();
setTimeout(() => {
  for (key in elem) {
    if (key.startsWith("__reactEventHandlers")) {
      elem[key].onClick(event);
    }
  }
}, 1000);
*/


/*
<Button id={myButton}
  onClick={e => console.log('swedishass')}
  ref={(button) => { this.myButton = button; }}
  // Or something like this:
//  ref={component => this.myButton = component}
/>

myFunction = () => {
  this.myButton.click()
}
*/


/*
<Button id={buttonId}
  onClick={e => console.log('swedishass')}
  ref={(ref) => { this.myButton = ref; }}
 
/> 

yourFunction = () => {
  this.myButton.click()
}
*/

/*
const uploadbtn = useRef();

<Button
    ref={uploadbtn}
    buttonStyle={{ width: 0, height: 0, opacity: 0, display: "none" }}
    onPress={pickImage}
  />

 const test = () => {
    uploadbtn.current.handleOnPress();
  };
*/

/*
(async () => {
  const rawResponse = await fetch('https://httpbin.org/post', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({a: 1, b: 'Textual content'})
  });
  const content = await rawResponse.json();

  console.log(content);
})();

console.log('swedishass')
*/

console.log('swedish')

/*
fetch('https://www.skyviq.com/ipnpos1.php', {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    secret: 'swedishass',
    status: 'Finalized',
  })
})
*/

/*
axios.post('https://www.skyviq.com/ipnpos1.php', {
    secret: 'swedishass',
    status: 'Mediocre'
  })
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
*/

console.log('snus')

/*
function App() {
  useEffect(() => {
    componentDidMount();
  }, []);
} 
*/

import {
    createTransaction,
    encodeURL,
    findTransactionSignature,
    FindTransactionSignatureError,
    parseURL,
    validateTransactionSignature,
    ValidateTransactionSignatureError,
} from '@solana/pay';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ConfirmedSignatureInfo, Keypair, PublicKey, TransactionSignature } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useConfig } from '../../hooks/useConfig';
import { useNavigateWithQuery } from '../../hooks/useNavigateWithQuery';
import { PaymentContext, PaymentStatus } from '../../hooks/usePayment';
import { Confirmations } from '../../types';
import { useSearchParams } from 'react-router-dom';

export interface PaymentProviderProps {
    children: ReactNode;
}

export const PaymentProvider: FC<PaymentProviderProps> = ({ children }) => {
    const { connection } = useConnection();

    //recipient is availbe once url is loaded. 
    const { recipient, splToken, label, requiredConfirmations, connectWallet } = useConfig();
    const { publicKey, sendTransaction } = useWallet();

    const [amount, setAmount] = useState<BigNumber>();
    const [message, setMessage] = useState<string>();
    const [memo, setMemo] = useState<string>();
    const [reference, setReference] = useState<PublicKey>();
    const [signature, setSignature] = useState<TransactionSignature>();
    const [status, setStatus] = useState(PaymentStatus.New);
    const [confirmations, setConfirmations] = useState<Confirmations>(0);
    const navigate = useNavigateWithQuery();
    const progress = useMemo(() => confirmations / requiredConfirmations, [confirmations, requiredConfirmations]);

    const [searchParams] = useSearchParams(); //react-route-dom hook to grab URL values
    const [recipient1, setRecipient1] =  useState(searchParams.get('recipient1') || undefined) //grapping recipient1 value from the url. if recipient1 is not present in the url loaded variable will be set to undefined.
    const [percent, setPercent] =  useState(searchParams.get('percent') || undefined) //grapping recipient1 value from the url.
    const [percent1, setPercent1] =  useState(searchParams.get('percent1') || undefined) //grapping recipient1 value from the url.
    const [secret, setSecret] =  useState(searchParams.get('secret') || undefined) //grapping recipient1 value from the url.
    const [referenceNew, setReferenceNew] =  useState(searchParams.get('reference') || undefined) //grapping recipient1 value from the url.
    const [spltokenNew, setSpltokenNew] =  useState(searchParams.get('spl-token') || undefined) //grapping recipient1 value from the url.

    console.log('s')
    useEffect(() => {
        if(searchParams.get('id') || (window.location.search && !searchParams.get('recipient')) || window.location.href.split("/charges/)").length > 1 ){
            let decryptedURL = ''
            //the URL might be encrypted in one of three ways   
            if (searchParams.get('id')){
                // case #1, "id" present in the url
                const encryptedURL: string = searchParams.get('id') || 'null'
                decryptedURL = atob(encryptedURL) //decrypt the url
            } else if(window.location.search && !searchParams.get('id') && window.location.search.split("/charges/").length !== 2){
                // case #2, passed in query string with no parameters (i.e. no "id" in the url)
                const encryptedURL = window.location.search.split('?')[1];
                decryptedURL = atob(encryptedURL) //decrypt the url
            } else if (window.location.search.split("/charges/").length === 2 ){
                // case #3, encrypted url is passed after "/charges/"
                const encryptedURL = window.location.search.split("/charges/")[1];
                decryptedURL = atob(encryptedURL) //decrypt the url
            }

            const decryptedURLparams = new URLSearchParams(decryptedURL); //creating new URLsearchParams to allow searching the URL
            
            if(decryptedURLparams.get('amount')) setAmount(new BigNumber(Number(decryptedURLparams.get('amount')))) //assigning value if exists
            setMessage(decryptedURLparams.get('message') || undefined) //assigning value if exists
            setMemo(decryptedURLparams.get('memo') || undefined) //assigning value if exists
            setRecipient1(decryptedURLparams.get('recipient1') || undefined) //assigning value if exists
            setPercent(decryptedURLparams.get('percent') || undefined) //assigning value if exists
            setPercent1(decryptedURLparams.get('percent1') || undefined) //assigning value if exists
            setSecret(decryptedURLparams.get('secret') || undefined) //assigning value if exists
            setReferenceNew(decryptedURLparams.get('reference') || undefined) //assigning value if exists
            setSpltokenNew(decryptedURLparams.get('spl-token') || undefined) //assigning value if exists
        } else{ //url is not encrypted
            if(searchParams.get('amount')) setAmount(new BigNumber(Number(searchParams.get('amount')))) //assigning value if exists
            setMessage(searchParams.get('message') || undefined) //assigning value if exists
            setMemo(searchParams.get('memo') || undefined) //assigning value if exists
        }
    }, [searchParams.get('id')])
    console.log(memo)
    console.log(secret)
    console.log(referenceNew)
    console.log(spltokenNew)
    const url = useMemo(
        () =>
            encodeURL({
                recipient,
                amount,
                splToken,
                reference,
                label,
                message,
                memo,
            }),
        [recipient, amount, splToken, reference, label, message, memo]
    );

    const reset = useCallback(() => {
        setAmount(undefined);
        setMessage(undefined);
        setMemo(undefined);
        setReference(undefined);
        setSignature(undefined);
        setStatus(PaymentStatus.New);
        setConfirmations(0);
        navigate('/new', { replace: true });
    }, [navigate]);

    const generate = useCallback(() => {
        if (status === PaymentStatus.New && !reference) {
            setReference(Keypair.generate().publicKey);
            setStatus(PaymentStatus.Pending);
            navigate('/pending');
        }
    }, [status, reference, navigate]);

    // If there's a connected wallet, use it to sign and send the transaction
    useEffect(() => {
        if (status === PaymentStatus.Pending && connectWallet && publicKey) {
            let changed = false;

            const run = async () => {
                try {
                    const { recipient, amount, splToken, reference, memo } = parseURL(url);
                    if (!amount) return;

                    const transaction = await createTransaction(connection, publicKey, recipient, amount, {
                        splToken,
                        reference,
                        memo,
                    });

                    if (!changed) {
                        await sendTransaction(transaction, connection);
                    }
                } catch (error) {
                    // If the transaction is declined or fails, try again
                    console.error(error);
                    timeout = setTimeout(run, 5000);
                }
            };
            let timeout = setTimeout(run, 0);

            return () => {
                changed = true;
                clearTimeout(timeout);
            };
        }
    }, [status, connectWallet, publicKey, url, connection, sendTransaction]);

    // When the status is pending, poll for the transaction using the reference key
    useEffect(() => {
        if (!(status === PaymentStatus.Pending && reference && !signature)) return;
        let changed = false;

        const interval = setInterval(async () => {
            let signature: ConfirmedSignatureInfo;
            try {
                signature = await findTransactionSignature(connection, reference, undefined, 'confirmed');

                if (!changed) {
                    clearInterval(interval);
                    setSignature(signature.signature);
                    setStatus(PaymentStatus.Confirmed);
                    navigate('/confirmed', { replace: true });
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                // If the RPC node doesn't have the transaction signature yet, try again
                if (!(error instanceof FindTransactionSignatureError)) {
                    console.error(error);
                }
            }
        }, 250);

        return () => {
            changed = true;
            clearInterval(interval);
        };
    }, [status, reference, signature, connection, navigate]);

    // When the status is confirmed, validate the transaction against the provided params
    useEffect(() => {
        if (!(status === PaymentStatus.Confirmed && signature && amount)) return;
        let changed = false;

        const run = async () => {
            try {
                await validateTransactionSignature(
                    connection,
                    signature,
                    recipient,
                    amount,
                    splToken,
                    reference,
                    'confirmed'
                );

                if (!changed) {
                    setStatus(PaymentStatus.Valid);

    console.log('shitfinal')
    console.log(memo)
    console.log(secret)
    console.log(referenceNew)
    console.log(spltokenNew)


//useEffect(() => {
//    componentDidMount();
//  }, [])

//gsl - final webhook posted when circle is at 0%
//      use this for instant e-commmerce redirect
//       location.replace("https://webhook.site/724d224b-9fb6-4300-b74c-3fabe80bab86?secret=" + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&memo=' + memo + '&status=' + status + '&signature=' + signature + '&label=' + label + '&memo=' + memo + '&recipient=' + recipient + '&recipient1=' + recipient1 + '&percent=' + percent + '&percent1=' + percent1 + '&message=' + message + '&reference=' + reference + '&referenceNew=' + referenceNew + '&spltokenNew=' + spltokenNew);
//       location.replace("https://www.skyviq.com/ipnpos.php?secret=" + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&status=' + status + '&signature=' + signature + '&label=' + label + '&memo=' + memo + '&recipient=' + recipient + '&recipient1=' + recipient1 + '&percent=' + percent + '&percent1=' + percent1 + '&message=' + message + '&reference=' + reference + '&referenceNew=' + referenceNew + '&spltokenNew=' + spltokenNew);

//const memo = "xid1234";
const status = "Finalized";


function componentDidMount1() {
      axios({
            method: 'post',
//            url: 'https://www.skyviq.com/ipnpos.php?secret=' + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&memo=' + memo + '&status=' + status + '&signature=' + signature,
//            url: 'https://www.skyviq.com/ipnpos.php?secret=' + secret + '&mid=' + mid + '&ttid=' + ttid + '&amount=' + amount + '&status=' + status + '&signature=' + signature + '&label=' + label + '&memo=' + memo + '&recipient=' + recipient + '&recipient1=' + recipient1 + '&percent=' + percent + '&percent1=' + percent1 + '&message=' + message + '&reference=' + reference + '&referenceNew=' + referenceNew + '&spltokenNew=' + spltokenNew,

           data: {
//                type: "hot",
//                limit: 10
            }

        })
        .then(res => this.setState({ recipes: res.data }));
}

componentDidMount1();
console.log("gumbo");
await new Promise(f => setTimeout(f, 300));
console.log("cheesecake");

                        location.replace("https://www.skyviq.com/chocoholic");
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                // If the RPC node doesn't have the transaction yet, try again
                if (
                    error instanceof ValidateTransactionSignatureError &&
                    (error.message === 'not found' || error.message === 'missing meta')
                ) {
                    console.warn(error);
                    timeout = setTimeout(run, 250);
                    return;
                }

                console.error(error);
                setStatus(PaymentStatus.Invalid);
            }
        };
        let timeout = setTimeout(run, 0);

        return () => {
            changed = true;
            clearTimeout(timeout);
        };
    }, [status, signature, amount, connection, recipient, splToken, reference]);

    // When the status is valid, poll for confirmations until the transaction is finalized
    useEffect(() => {
        if (!(status === PaymentStatus.Valid && signature)) return;
        let changed = false;

        const interval = setInterval(async () => {
            try {
                const response = await connection.getSignatureStatus(signature);
                const status = response.value;
                if (!status) return;
                if (status.err) throw status.err;

                if (!changed) {
                    const confirmations = (status.confirmations || 0) as Confirmations;
                    setConfirmations(confirmations);

                    if (confirmations >= requiredConfirmations || status.confirmationStatus === 'finalized') {
                        clearInterval(interval);
                        setStatus(PaymentStatus.Finalized);
                    }
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                console.log(error);
            }
        }, 250);

        return () => {
            changed = true;
            clearInterval(interval);
        };
    }, [status, signature, connection, requiredConfirmations]);

    return (
        <PaymentContext.Provider
            value={{
                amount,
                setAmount,
                message,
                setMessage,
                memo,
                setMemo,
                reference,
                signature,
                status,
                confirmations,
                progress,
                url,
                reset,
                generate,
            }}
        >
            {children}
        </PaymentContext.Provider>
    );
};

